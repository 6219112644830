var render = function () {
  var _vm$bookingData, _vm$bookingData2, _vm$bookingData3, _vm$bookingData4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "footer"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: " p-1 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: " d-flex text-subtitle text-info text-nowrap"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.$t('reservation.total')) + ":")]), _c('div', [_vm._v(_vm._s(_vm.formatCurrency(_vm.totalPrice)))])]), _c('b-button', {
    attrs: {
      "variant": "gradient border-0",
      "pill": "",
      "disabled": _vm.tabIndexNotSelect === _vm.tabIndex
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.tabIndexNotSelect >= 0 && _vm.tabIndexNotSelect !== _vm.tabIndex && _vm.selectedFlightIndex.length > 1 ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('flight.nextFlight')) + " ")]) : _c('div', [_vm._v(" Tính giá ")])])], 1)]), _c('b-modal', {
    attrs: {
      "id": "modal-modify-change-flight-price",
      "title": '2. Xác nhận đổi chuyến bay',
      "title-class": "text-heading-3 text-airline font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showPriceHandle,
      "hide": _vm.hidePriceHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill px-2",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" QUAY LẠI ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mt-lg-25",
          attrs: {
            "variant": _vm.isPayNow ? 'success' : 'warning',
            "disabled": !_vm.isConfirm || !_vm.priceChange,
            "pill": ""
          },
          on: {
            "click": _vm.submitChangeFlightHandle
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.isPayNow ? 'THANH TOÁN NGAY' : 'THANH TOÁN SAU') + " ")])])];
      }
    }])
  }, [_c('div', {
    staticClass: "text-heading-4 mb-50 text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.oldFlight')) + ": ")]), _vm._l(_vm.itinerariesByIndexSelected, function (itinerary, index) {
    return _c('b-card', {
      key: index,
      staticClass: "mb-sm-2 shadow-lg border-danger mx-75 mx-md-3",
      attrs: {
        "body-class": "py-50 py-md-1 px-50 px-md-1"
      }
    }, _vm._l(itinerary, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('div', {
        staticClass: "d-flex flex-wrap align-items-center font-weight-bolder ml-md-3"
      }, [_c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(indexSegment + 1))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.airline) + _vm._s(segment.flightNumber))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.bookingClass))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).dayMonth))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.departure.iataCode) + _vm._s(segment.arrival.iataCode))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.status))]), _c('span', {}, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timeZone).hourMin) + " " + _vm._s(_vm.convertISODateTime(segment.arrival.at, segment.arrival.timeZone).hourMin))])])]);
    }), 0);
  }), _c('div', {
    staticClass: "text-heading-4 mb-50 text-success"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.newFlight')) + ": ")]), !_vm.isEmpty(_vm.selectedTrip) ? _c('div', _vm._l(_vm.selectedTrip.filter(function (item) {
    return item;
  }), function (itinerary, index) {
    return _c('b-card', {
      key: index,
      staticClass: "mb-sm-2 shadow-lg border-success mx-75 mx-md-3",
      attrs: {
        "body-class": "py-50 py-md-1 px-50 px-md-1"
      }
    }, _vm._l(itinerary.segments, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('div', {
        staticClass: "d-flex flex-wrap align-items-center font-weight-bolder ml-md-3"
      }, [_c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(indexSegment + 1))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.airline) + _vm._s(segment.flightNumber))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(['VN1A_MT', 'VN1A', 'QH'].includes(_vm.bookingData.source) ? itinerary.fareOptions.bookingClass.split(' | ')[indexSegment] : itinerary.fareOptions && itinerary.fareOptions.bookingClass ? itinerary.fareOptions.bookingClass : segment.bookingClass))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).dayMonth))]), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.departure.IATACode) + _vm._s(segment.arrival.IATACode))]), segment.status ? _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(segment.status))]) : _vm._e(), _c('span', {
        staticClass: "mr-50"
      }, [_vm._v(_vm._s(_vm.convertISODateTime(segment.departure.at, segment.departure.timezone).hourMin))]), _c('span', {}, [_vm._v(_vm._s(_vm.convertISODateTime(segment.arrival.at, segment.arrival.timezone).hourMin))])])]);
    }), 0);
  }), 1) : _vm._e(), ['QH', '1A'].includes((_vm$bookingData = _vm.bookingData) === null || _vm$bookingData === void 0 ? void 0 : _vm$bookingData.source) ? _c('div', [_vm.priceChange ? _c('div', {
    staticClass: "text-dark font-weight-bold font-medium-2 d-flex justify-content-end align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.$t('reservation.totalAmountNeedToPay')) + ":")]), _c('span', {
    staticClass: "text-warning font-weight-bolder font-medium-4 mr-1"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.priceChange.reduce(function (total, item) {
    return total + item.pricingInfo.total;
  }, 0))) + " (VND)")])]) : _vm.errorChange ? _c('div', [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.errorChangeFlight')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.errorChange))])]) : _vm.priceChange === false ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.calcPriceForChangeFlight')) + "... "), _c('p', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info",
      "small": ""
    }
  })], 1)]) : _vm._e()]) : _vm._e(), ['VJ'].includes((_vm$bookingData2 = _vm.bookingData) === null || _vm$bookingData2 === void 0 ? void 0 : _vm$bookingData2.source) ? _c('div', [_vm.priceChange ? _c('BCard', {
    staticClass: "border mb-50",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "py-50",
      "body-class": "mt-75 pb-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-between font-weight-bolder"
        }, [_c('span', [_vm._v("Tính giá thành công")]), _c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-lg",
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function click() {
              return _vm.isHideSomePriceField = !_vm.isHideSomePriceField;
            }
          }
        }, [_vm.isHideSomePriceField ? _c('feather-icon', {
          attrs: {
            "icon": "EyeIcon",
            "size": "16"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "EyeOffIcon",
            "size": "16"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 2517668130)
  }, [_vm.priceChange.oldPrice && !_vm.isHideSomePriceField ? _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Giá vé cũ: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.oldPrice)) + " (VND) ")])], 1) : _vm._e(), _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Phí đổi vé: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.fee)) + " (VND) ")])], 1), _c('BRow', {
    staticClass: "mx-0 mb-25 align-items-center"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Tiền chênh lệch hành trình mới: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.difference)) + " (VND) ")])], 1), _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "fw-700 text-danger"
  }, [_vm._v("Tổng tiền cần thanh toán:")])]), _c('BCol', {
    staticClass: "text-right text-danger font-medium-2 fw-700",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.total)) + " (VND) ")])], 1), !_vm.isHideSomePriceField ? _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Tổng tiền vé sau thanh toán: ")]), _c('BCol', {
    staticClass: "text-right text-warning font-medium-2 fw-700",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.newPrice)) + " (VND) ")])], 1) : _vm._e()], 1) : _vm.errorChange ? _c('div', [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.errorChangeFlight')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.errorChange))])]) : _vm.priceChange === false ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.calcPriceForChangeFlight')) + "... "), _c('p', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  })], 1)]) : _vm._e(), _c('b-alert', {
    staticClass: "fw-700 p-75 text-center mt-1",
    attrs: {
      "show": ['VJ'].includes((_vm$bookingData3 = _vm.bookingData) === null || _vm$bookingData3 === void 0 ? void 0 : _vm$bookingData3.source),
      "variant": "danger "
    }
  }, [_c('div', [_vm._v("Lưu ý vé có hành lý (dịch vụ) đi kèm vui lòng kiểm tra lại booking, (có thể bị rớt hành lý sau khi đổi).")])])], 1) : _vm._e(), ['VN1A_MT', 'VN1A'].includes((_vm$bookingData4 = _vm.bookingData) === null || _vm$bookingData4 === void 0 ? void 0 : _vm$bookingData4.source) ? _c('div', [_vm.priceChange ? _c('BCard', {
    staticClass: "border mb-50",
    attrs: {
      "header-bg-variant": "light-success",
      "header-class": "py-50",
      "body-class": "mt-75 pb-75"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 d-flex-between font-weight-bolder"
        }, [_c('span', [_vm._v("Tính giá thành công")]), _c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-lg",
          attrs: {
            "variant": "outline-warning"
          },
          on: {
            "click": function click() {
              return _vm.isHideSomePriceField = !_vm.isHideSomePriceField;
            }
          }
        }, [_vm.isHideSomePriceField ? _c('feather-icon', {
          attrs: {
            "icon": "EyeIcon",
            "size": "16"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "EyeOffIcon",
            "size": "16"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 2517668130)
  }, [_vm.priceChange.oldPrice && !_vm.isHideSomePriceField ? _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Giá vé cũ: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.oldPrice)) + " (VND) ")])], 1) : _vm._e(), _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Phí đổi vé: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.fee)) + " (VND) ")])], 1), _c('BRow', {
    staticClass: "mx-0 mb-25 align-items-center"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Tiền chênh lệch hành trình mới: ")]), _c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.newPrice - (_vm.priceChange.oldPrice + _vm.priceChange.fee))) + " (VND) ")])], 1), _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "fw-700 text-danger"
  }, [_vm._v("Tổng tiền cần thanh toán:")])]), _c('BCol', {
    staticClass: "text-right text-danger font-medium-2 fw-700",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.newPrice - _vm.priceChange.oldPrice)) + " (VND) ")])], 1), !_vm.isHideSomePriceField ? _c('BRow', {
    staticClass: "mx-0 mb-25"
  }, [_c('BCol', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" Tổng tiền vé sau thanh toán: ")]), _c('BCol', {
    staticClass: "text-right text-warning font-medium-2 fw-700",
    attrs: {
      "cols": "6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.priceChange.newPrice)) + " (VND) ")])], 1) : _vm._e()], 1) : _vm.errorChange ? _c('div', [_c('div', {
    staticClass: "text-danger text-heading-4"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.errorChangeFlight')) + " ")]), _c('div', [_vm._v(_vm._s(_vm.errorChange))])]) : _vm.priceChange === false ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('reservation.calcPriceForChangeFlight')) + "... "), _c('p', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "info"
    }
  })], 1)]) : _vm._e(), _c('b-alert', {
    staticClass: "fw-700 p-75 text-center mt-1",
    attrs: {
      "show": ['VJ'].includes(_vm.bookingData.source),
      "variant": "danger "
    }
  }, [_c('div', [_vm._v("Lưu ý vé có hành lý (dịch vụ) đi kèm vui lòng kiểm tra lại booking, (có thể bị rớt hành lý sau khi đổi).")])])], 1) : _vm._e(), _vm.bookingData && ['VJ'].includes(_vm.bookingData.source) ? _c('CardAncillaryPayOptions', {
    staticClass: "mt-2",
    attrs: {
      "reservations-data": _vm.bookingData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-1"
  }, [_c('IAmConfirm', {
    attrs: {
      "is-confirm": _vm.isConfirm
    },
    on: {
      "update:isConfirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      },
      "update:is-confirm": function updateIsConfirm($event) {
        _vm.isConfirm = $event;
      }
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }